//sitemap


document.addEventListener("DOMContentLoaded", function(){

    if (document.getElementById('faq').length === 0) return;

    const parents = document.querySelectorAll(".question");
    const children = document.querySelectorAll(".answer");
    
    
    for (let i = 0; i < parents.length; i++) {
      parents[i].addEventListener("click", function () {
          console.log("toggle");
        this.classList.toggle("open");
        children[i].classList.toggle("open");
      });
    }
});

