/**

 require('./bootstrap');

 window.Vue = require('vue').default;
 
 
// Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 
 Vue.component('example-component', require('./components/ExampleComponent.vue').default);
 
 const app = new Vue({
     el: '#app',
 });
 
 */
 
//トップページカルーセルバナー


if(document.querySelectorAll('.carousel_bnr')[0]){
    const swiper = new Swiper('.carousel_bnr', {
        // Optional parameters
        direction: 'horizontal',
        loop: true,
        slidesPerView: 'auto',
        spaceBetween: 50,
        autoplay: {
            delay: 2000,
        },
        centeredSlides: true,
        pagination: {
            el: '.swiper-pagination',
        },
    
    });
}



//汎用アコーディオン 
document.addEventListener("DOMContentLoaded", () => {
    const title = document.querySelectorAll('.js-accordion-title');

    for (let i = 0; i < title.length; i++) {
        let titleEach = title[i];
        let content = titleEach.nextElementSibling;
        titleEach.addEventListener('click', () => {
            titleEach.classList.toggle('is-active');
            content.classList.toggle('is-open');
        });
    }

});

//spハンバーガーメニュー
let hamburger = document.querySelector(".hamburger");
let modal = document.querySelector(".modal");
hamburger.addEventListener("click", function () {
    hamburger.classList.toggle("is-active");
    modal.classList.toggle("is-active");
});
